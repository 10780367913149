import React from 'react';

import Layout from '../components/Layout';
import UserMessage from '../components/UserMessage';

const NotFoundPage = () => (
  <Layout>
    <UserMessage type="notFound" />
  </Layout>
);

export default NotFoundPage;
